var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Layout, Dependencies } from '~/core/decorator';
import { namespace } from 'vuex-class';
import DataForm from '~/components/common/data-form.vue';
import DataBox from '~/components/common/data-box.vue';
import { PageService } from '~/utils/page.service';
import { SortService } from '~/utils/sort.service';
import NumberRange from '~/components/common/number-range.vue';
import AddExam from '~/components/assist-work/dia-log/add-exam.vue';
var inrushCaseModule = namespace('inrush-case');
//@Auth(77)
var OnlineExam = /** @class */ (function (_super) {
    __extends(OnlineExam, _super);
    function OnlineExam() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.undistributedModel = {};
        _this.examModel = {};
        _this.dialog = {
            addExam: false,
        };
        return _this;
    }
    OnlineExam.prototype.NewExamClick = function () {
        this.dialog.addExam = true;
    };
    OnlineExam.prototype.data = function () {
        return {
            tableData: [
                {
                    appointCaseData: "2016-05-02",
                    cardNumber: "王小虎",
                    caseNumber: "上海市普陀区金沙江路 1518 弄",
                    batchNumber: "简介"
                },
                {
                    appointCaseData: "2016-05-02",
                    cardNumber: "王小虎",
                    caseNumber: "上海市普陀区金沙江路 1518 弄",
                    batchNumber: "简介"
                }
            ]
        };
    };
    OnlineExam.prototype.refreshData = function () { };
    OnlineExam.prototype.StartExamClick = function () { };
    OnlineExam.prototype.AllDeleteClick = function () { };
    __decorate([
        Dependencies(PageService)
    ], OnlineExam.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], OnlineExam.prototype, "sortService", void 0);
    OnlineExam = __decorate([
        Layout('workspace'),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                AddExam: AddExam
            }
        })
    ], OnlineExam);
    return OnlineExam;
}(Vue));
export default OnlineExam;
